import React from "react";
import './Footer.css';

export default function Footer(){
    return (
       <div id="scroll-container">
           <div id="scroll-text">
               react node bootstrap javascript express postgresql html5 css3 mocha chai jest knex sql git heroku vercel best practices good coffee
            </div>
       </div> 
    );
}